
import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'

const Filter = memo((props) => {

    const location = useLocation();
    
    return (
        <section className="about-section secexpmn bgdep">
            <div className="auto-container">
                <div className="mnubt">
                    <ul>
                    <li className={location.pathname === '/Foods' ? 'mnbrat' : ''}><Link to={'/Foods'}>Foods</Link></li>
                    <li className={location.pathname === '/Drinks' ? 'mnbrat' : ''}><Link to={'/Drinks'}>Drinks</Link></li>
                        
                    </ul>
                </div>
            </div>
        </section>
    )
})


export default Filter